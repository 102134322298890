import React from 'react';
import { cva } from 'class-variance-authority';
import { Link, useNavigate } from 'react-router-dom';

const header = cva(['absolute top-0 right-0 left-0 bottom-[95vh] w-screen text-center p-2 border']);
const logoutBtn = cva(['ml-[30px]']);

const Header = () => {
  const navigate = useNavigate();
  
  const logout = () => {
    localStorage.removeItem('authToken');

    navigate('/login');
  }
  return (
    <header className={header()}>
        <Link className='mr-[10px]' to='/dashboard'>Рассылка</Link>
        <Link className='mr-[10px]' to='/stats'>Статистика</Link>
        <Link to='/settings'>Настройки</Link>
        <button onClick={logout} className={logoutBtn()}>Выйти из аккаунта</button>
    </header>
  );
};

export default Header;