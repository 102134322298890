import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { cva } from 'class-variance-authority';
import { Toaster } from 'react-hot-toast';

import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/auth/DashboardPage';
import Statistics from './pages/auth/Statistics';
import ReviewPage from './pages/ReviewPage';
import ProtectedRoute from './components/ProtectedRoute';
import Settings from './pages/auth/Settings';

const centerContent = cva(['h-[100vh] flex items-center justify-center']);

function App() {
  return (
    <>
      <Toaster />
      <Router>
        <div className={centerContent()}>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route 
              path="/dashboard" 
              element={
                <ProtectedRoute>
                  <DashboardPage />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/stats" 
              element={
                <ProtectedRoute>
                  <Statistics />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/settings" 
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              } 
            />
            <Route path="/" element={<ReviewPage />} />
            <Route path="/:uniqueId" element={<ReviewPage />} />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;