import React, { useEffect, useState } from 'react';
import toast from "react-hot-toast";
import type { StatisticsType } from '../../types/statistics';
import StatisticsTable from '../../components/StatisticsTable';
import { api } from '../../composables/useApi';

const Statistics = () => {
  const [statisticsData, setStatistics] = useState<StatisticsType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: any = await api('statistics', { method: 'GET' });
        
        setStatistics(response.allRecords);
        return;
      } catch (error: any) {
        console.error('Ошибка при загрузке данных:', error);

        toast.error(`Ошибка: ${error.message}`, {
          duration: 2000,
        })
        return;
      } finally {
        setLoading(false);
        return;
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {loading ? (
        <p>Загрузка...</p>
      ) : (
        statisticsData.length > 0 ? (
          <StatisticsTable statisticsData={statisticsData} />
        ) : (
          <p>Нет данных для отображения</p>
        )
      )}
    </div>
  );
};

export default Statistics;
