import { useEffect, useState } from "react";
import { cva } from 'class-variance-authority';
import toast from "react-hot-toast";
import { api } from "../../composables/useApi";

const mainDiv = cva(['border flex flex-col p-5 w-[50vw]'])
const ul = cva(['py-2 overflow-hidden'])
const textarea = cva(['p-2 caret-red focus:outline-none rounded bg-black text-white mb-[5px] min-h-[150px]'])
const button = cva(['bg-slate-500 w-[50%] text-white rounded-lg p-2 active:scale-95'])

const DashboardPage = () => {
    const [textareaValue, setTextareaValue] = useState<string>();
    const [numbersList, setNumbersList] = useState<string[]>([]);

    useEffect(() => {
        const textArr = textareaValue?.split('\n');

        if (!textareaValue) {
            setNumbersList([]);
            setTextareaValue(undefined);
        }

        if (textArr && textArr.length > 0) {
            setNumbersList(textArr);
        }
    }, [textareaValue])

    const download = async () => {
        if (!textareaValue) {
            toast.error('Поле пустое!', {
                duration: 2000,
            })
        }
        
        const response: any = await api('review', { method: 'POST', body: {
            clients: numbersList
        }});
        
        if (response?.success) {
            setNumbersList([]);
            setTextareaValue(undefined);

            toast.success('Успешно!', {
                duration: 2000,
            })
        } else {
            let error = response?.error ? response.message : '';

            toast.error(`Ошибка! ${error}`, {
                duration: 2000,
            })
        }
    }

    return (
        <div className={mainDiv()}>
            {numbersList.length > 0 && 
                <ul className={ul()}>
                    {numbersList.map((elem, index) => {
                        return (
                            <li>
                                {index + 1}. {elem}
                            </li>
                        ); 
                    })}
                </ul>
            }
            <textarea
                className={textarea()}
                onChange={(e) => setTextareaValue(e.target.value)}
            />
            <button
               className={button()} 
               onClick={download}
            >
                Загрузить
            </button>
        </div>
    );
};

export default DashboardPage;