import { useEffect, useState } from 'react';
import toast from "react-hot-toast";
import axios from 'axios';
import { cva } from 'class-variance-authority';
import { useNavigate } from 'react-router-dom';
import { checkToken } from '../composables/checkJwt';

const mainBlock = cva(['p-5 min-h-[250px] w-[50vw] mx-auto flex flex-col justify-between mx-auto border']);
const h2 = cva(['text-[24px] text-center']);
const input = cva(['border p-2']);
const button = cva(['border w-[50%] text-center active:scale-95']);
const buttonDiv = cva(['flex justify-center']);

const LoginPage = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
      
        if (authToken) {
          const auth = async () => {
            const result = await checkToken();
      
            if (result) {
                navigate('/dashboard');
            } else {
                localStorage.removeItem('authToken');
            }
          };
      
          auth();
        }
    }, []);
    
    const throwError = (message: string) => {
        toast.error(`${message}`, {
            duration: 2000,
        })
    }

    const handleLogin = async () => {
        if (!email) return throwError('Email обязателен');
        if (!password) return throwError('Пароль обязателен');

        await axios.post(`${process.env.REACT_APP_API_URL}users/login`, {
            email: email,
            password: password
        })
        .then((res) => {
            localStorage.setItem('authToken', res.data.token);

            navigate('/dashboard');
        })
        .catch((err) => {
            throwError(`${err.response.data.message}`);
        })
    };

    return (
        <div className={mainBlock()}>
            <h2 className={h2()}>Login</h2>
            <input className={input()}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder='email'
                />
            <input className={input()}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                placeholder='password'
                />
            <div className={buttonDiv()}>
                <button
                    onClick={handleLogin}
                    className={button()}    
                >
                    Login
                </button>
            </div>
        </div>
    );
};

export default LoginPage;