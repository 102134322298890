import { useEffect, useState } from "react";
import { cva } from 'class-variance-authority';
import toast from "react-hot-toast";
import { api } from "../../composables/useApi";

const mainBlock = cva(['p-5 min-h-[250px] w-[50vw] mx-auto flex flex-col justify-between border']);
const h2 = cva(['text-[24px] text-center']);
const input = cva(['border p-2 mb-2']);
const button = cva(['border w-[50%] text-center active:scale-95']);
const buttonDiv = cva(['flex justify-center']);
const buttonWrapper = cva(['flex flex-col']);

const Settings = () => {
    const [settings, setSettings] = useState({
        clinicName: '',
        yandexMap: '',
        googleMap: '',
        twoGis: ''
    });

    const [newSettings, setNewSettings] = useState({
        clinicName: '',
        yandexMap: '',
        googleMap: '',
        twoGis: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            const response: any = await api('users/getSettings', { method: 'GET' });

            if (response.clinicName) {
                const buttons = response.buttons || {};
                setSettings({
                    clinicName: response.clinicName,
                    yandexMap: buttons.yandexMapLink || '',
                    googleMap: buttons.googleMapsLink || '',
                    twoGis: buttons.twoGisLink || ''
                });
                setNewSettings({
                    clinicName: response.clinicName,
                    yandexMap: buttons.yandexMapLink || '',
                    googleMap: buttons.googleMapsLink || '',
                    twoGis: buttons.twoGisLink || ''
                });
            }
        };

        fetchData();
    }, []);

    const handleUpdate = async () => {
        try {
            const response: any = await api('users/updateSettings', {
                method: 'POST',
                body: {
                    clinicName: newSettings.clinicName,
                    buttons: {
                        yandexMapLink: newSettings.yandexMap,
                        googleMapsLink: newSettings.googleMap,
                        twoGisLink: newSettings.twoGis
                    }
                }
            });

            if (response.success) {
                toast.success('Успешно!');

                setSettings({
                    clinicName: newSettings.clinicName,
                    yandexMap: newSettings.yandexMap,
                    googleMap: newSettings.googleMap,
                    twoGis: newSettings.twoGis
                });

                setNewSettings({
                    clinicName: '',
                    yandexMap: '',
                    googleMap: '',
                    twoGis: ''
                });

                return;
            }
        } catch (err: any) {
            toast.error(`Error: ${err.message}`);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewSettings((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const inputFields = [
        { label: 'Имя компании:', name: 'clinicName', placeholder: settings.clinicName || 'Clinic name' },
        { label: 'Ссылка на google maps:', name: 'googleMap', placeholder: settings.googleMap || 'Google map link' },
        { label: 'Ссылка на yandex maps:', name: 'yandexMap', placeholder: settings.yandexMap || 'Yandex map link' },
        { label: 'Ссылка на 2ГИС:', name: 'twoGis', placeholder: settings.twoGis || '2гис map link' },
    ];

    return (
        <div className={mainBlock()}>
            <h2 className={h2()}>Settings</h2>
            {inputFields.map(({ label, name, placeholder }) => (
                <div className={buttonWrapper()} key={name}>
                    <label htmlFor={name}>{label}</label>
                    <input
                        className={input()}
                        type="text"
                        name={name}
                        value={newSettings[name as keyof typeof newSettings]}
                        onChange={handleChange}
                        placeholder={placeholder}
                    />
                </div>
            ))}
            <div className={buttonDiv()}>
                <button onClick={handleUpdate} className={button()}>Update</button>
            </div>
        </div>
    );
};

export default Settings;
