import { useEffect, useState } from 'react';
import { cva } from 'class-variance-authority';
import { motion } from 'framer-motion';
import ReviewNo from './ReviewNo';
import ReviewYes from './ReviewYes';
import axios from 'axios';

const wrapper = cva(['w-[310px] overflow-hidden min-h-[600px] border rounded-md p-5']);
const h1 = cva(['text-[30px] font-bold leading-tight mb-[10px]']);
const p = cva(['text-[18px] mb-[30px] font-medium']);
const buttonWrapper = cva(['flex flex-col mb-[10px]']);
const button = cva(['flex pl-[50px] border border-2 mb-[10px] py-2 font-semibold text-sky-500 rounded-md']);
const agreement = cva(['text-zinc-400 text-[14px] max-w-[250px]']);
const agreementSpan = cva(['underline underline-offset-1']);

interface ReviewStartProps {
    reviewId: string;
}

const ReviewStart: React.FC<ReviewStartProps> = ({ reviewId }) => {
    const [showReview, setShowReview] = useState<string | null>(null);
    const [clinicName, setClinicName] = useState<string | null>(null);

    const apiUrl = `${process.env.REACT_APP_API_URL}review`;

    const updateRecord = async (field: string, value: any) => {
        try {
            await axios.post(`${apiUrl}/update`, {
                reviewId,
                field,
                value,
            });
        } catch (error) {
            console.error(`Error updating record for field ${field}:`, error);
        }
    };

    const getInfo = async () => {
        try {
            const response: any = await axios.get(`${apiUrl}/info/${reviewId}`);
            setClinicName(response.data.clinicName);
        } catch (error) {
            console.error('Error fetching info:', error);
        }
    };

    useEffect(() => {
        const updateReviewStatus = async () => {
            if (showReview) {
                await updateRecord('grade', showReview === 'yes' ? 'Доволен' : 'Недоволен');
            }
        };

        updateReviewStatus();
    }, [showReview]);

    useEffect(() => {
        const initialize = async () => {
            await getInfo();
            await updateRecord('redirect', true);
            await updateRecord('redirectedAt', new Date());
        };

        initialize();
    }, []);

    const handleYesClick = () => setShowReview('yes');
    const handleNoClick = () => setShowReview('no');

    return (
        <div className={wrapper()}>
            {showReview === null ? (
                <motion.div
                    initial={{ x: 0 }}
                    animate={{ x: 0 }}
                    exit={{ x: -100 }}
                    transition={{ duration: 1 }}
                >
                    <h1 className={h1()}>Вам понравилось обслуживание в {clinicName}?</h1>
                    <p className={p()}>Поделитесь своим мнением с другими людьми</p>
                    <div className={buttonWrapper()}>
                        <button className={button()} onClick={handleYesClick}>😄 Да, всё отлично</button>
                        <button className={button()} onClick={handleNoClick}>🙁 Нет, есть претензии</button>
                    </div>
                    <p className={agreement()}>Выражая своё мнение о качестве обслуживания Вы соглашаетесь с <span className={agreementSpan()}>правилами сервиса</span></p>
                </motion.div>
            ) : (
                <motion.div
                    key={showReview}
                    initial={{ x: 100 }}
                    animate={{ x: 0 }}
                    exit={{ x: -100 }}
                    transition={{ duration: 0.5 }}
                >
                    {showReview === 'yes' ? <ReviewYes reviewId={reviewId} /> : <ReviewNo reviewId={reviewId} />}
                </motion.div>
            )}
        </div>
    );
};

export default ReviewStart;
