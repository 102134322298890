import type { StatisticsType } from "../types/statistics"
import { formatDate } from "../composables/formatDate";

interface StatisticsTableProps {
    statisticsData: StatisticsType[];
}

const StatisticsTable = ({ statisticsData }: StatisticsTableProps) => {
   return (
    <table>
        <thead>
            <tr>
                <th>ID</th>
                <th>Клиент</th>
                <th>Номер</th>
                <th>СМС статус</th>
                <th>Дата создания</th>
                <th>Дата перехода</th>
                <th>Короткая ссылка</th>
                <th>Переход по ссылке</th>
                <th>Оценка</th>
                <th>Сообщение</th>
                <th>Переход по кнопке</th>
            </tr>
        </thead>
        <tbody>
            {statisticsData.length > 0 ? (
                statisticsData.map((reviewItem: StatisticsType) => (
                    <tr key={reviewItem.id}>
                        <td>{reviewItem.id}</td>
                        <td>{reviewItem.client}</td>
                        <td>{reviewItem.number}</td>
                        <td>{reviewItem.smsArrived ? 'Доставлено' : 'Не доставлено'}({reviewItem.httpCode})</td>
                        <td>{formatDate(new Date(reviewItem.createdAt))}</td>
                        <td>{reviewItem.redirectedAt && formatDate(new Date(reviewItem.redirectedAt))}</td>
                        <td>{reviewItem.link}</td>
                        <td>{reviewItem.redirect ? 'Да' : 'Нет'}</td>
                        <td>{reviewItem.grade}</td>
                        <td>{reviewItem.message}</td>
                        <td>{reviewItem.redirectOnButton}</td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td colSpan={11}>Нет данных</td>
                </tr>
            )}
        </tbody>
    </table>
   ) 
}

export default StatisticsTable;