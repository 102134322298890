import axios, { AxiosRequestConfig } from 'axios';

interface ApiOptions {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  headers?: Record<string, string>;
  body?: Record<string, any>; 
}

export async function api<T>(url: string, options: ApiOptions): Promise<T | undefined> {
  const authToken = localStorage.getItem('authToken');

  if(!authToken) {
    window.location.href = "/login";
    return;
  }

  const config: AxiosRequestConfig = {
    method: options.method,
    url: process.env.REACT_APP_API_URL + url,
    headers: {
      Authorization: authToken ? `Bearer ${authToken}` : '',
      ...options.headers,
    },
    data: options.body ? options.body : undefined,
  };

  try {
    const response = await axios(config);

    return response.data as T;
  } catch (error: any) {
    if (error.response) {
      const { status, data } = error.response;

      if (status === 401) {
        console.error('Unauthorized, redirecting to login');

        localStorage.removeItem('authToken');
        window.location.href = "/login";
      } else {
        console.error('API Error:', data.message);
      }
    } else {
      console.error('Error:', error.message);
    }
  }

  return undefined;
}


