import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { checkToken } from '../composables/checkJwt';
import Header from './Header';

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps): ReactElement | null => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation(); 

  const verifyToken = async () => {
    const authStatus = await checkToken();
    setIsAuthenticated(authStatus);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    verifyToken();
  }, [location]);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <Header />
      {children}
    </div>
  );
};

export default ProtectedRoute;
