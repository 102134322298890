import axios from 'axios';
import { cva } from 'class-variance-authority';
import { useState } from 'react';
import toast from "react-hot-toast";

const h1 = cva(['text-[30px] font-bold leading-tight mb-[10px]']);
const p = cva(['text-[16px] mb-[30px]']);
const textarea = cva(['focus:outline-none text-[15px] p-2 bg-slate-100 w-full min-h-[100px] rounded-md mb-[20px]']);
const button = cva(['w-full disabled:opacity-50 mb-[10px] bg-slate-500 text-center py-2 rounded-md text-white']);
const info = cva(['leading-tight text-zinc-400 text-[14px] max-w-[250px]']);

interface ReviewProps {
    reviewId: string;
}

const ReviewNo: React.FC<ReviewProps> = ({ reviewId }) => {
    const [message, setMessage] = useState<string>();
    const [finished, setFinished] = useState<boolean>(false);

    const sendMessage = async () => {
        if (!message?.trim()) {
            toast.error(`Вы не написали сообщение`, {
                duration: 2000,
            })
        }

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}review/update`, {
                reviewId: reviewId,
                field: 'message',
                value: message?.trim(),
            })
            
            toast.success(`Спасибо, Вы успешно оставили сообщение директору`, {
                duration: 2000,
            })
            setFinished(true);
            return;
        } catch (error) {
            
        }
    }

    return (
        <>
            {finished ? 
                <>
                    <h1 className={h1()}>Спасибо за Ваше сообщение!</h1>
                </> :
                <>
                    <h1 className={h1()}>Напишите директору 😞</h1>
                    <p className={p()}>Расскажите, что Вам не понравилось и что мы можем делать лучше. Директор клиники накажет виновных, свяжется с Вами и предложит решение проблемы.</p>
                    <textarea
                        className={textarea()}
                        name="message"
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <button
                        className={button()}
                        disabled={!message?.trim()}
                        onClick={sendMessage}
                    >
                        Отправить
                    </button>
                    <p className={info()}>Чтобы отправить письмо директору, напишите свой комментарий.</p>
                </>
            }
        </>
    )
}

export default ReviewNo