import axios from 'axios';

export const checkToken = async (): Promise<boolean> => {
    const token = localStorage.getItem('authToken');

    if (!token) {
        return false;
    };
    
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}validate-token`, { headers: { Authorization: `Bearer ${token}` } });
        
        return response.data.success ? true : false;
    } catch (error) {
        localStorage.removeItem('authToken');
        return false;
    }
};