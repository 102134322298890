import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReviewStart from '../components/reviews/ReviewStart';
import axios from 'axios';
import { api } from '../composables/useApi';

const ReviewPage = () => {
  const { uniqueId } = useParams<{ uniqueId: string }>();
  const [existingPage, setExistingPage] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(true);
  const [finished, setFinished] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response: any = await api(`review/${uniqueId}`, { method: 'GET' });
        const response: any = await axios.get(`${process.env.REACT_APP_API_URL}review/${uniqueId}`);

        const { exists, message, redirectOnButton } = response.data;

        setExistingPage(exists);

        if (message || redirectOnButton) {
          setFinished(true);
        }
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {loading ? (
        <h1>Загрузка...</h1>
      ) : finished ? (
        <h1>Спасибо, что оставили отзыв!</h1>
      ) : (
        existingPage && uniqueId ? (
          <ReviewStart reviewId={uniqueId} />
        ) : (
          <h1>Страницы не существует либо ссылка уже была использована</h1>
        )
      )}
    </div>
  );
};

export default ReviewPage;