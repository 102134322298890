import { SVGProps, useEffect, useState } from 'react';
import { cva } from 'class-variance-authority';
import { ReactComponent as YandexMapSvg } from '../../icons/yandexMap.svg';
import { ReactComponent as GoogleMapSvg } from '../../icons/googleMap.svg';
import { ReactComponent as TwoGisSvg } from '../../icons/2gis.svg';
import axios from 'axios';

const h1 = cva(['text-[30px] font-bold leading-tight mb-[10px]']);
const p = cva(['text-[16px] mb-[15px]']);
const buttonWrapper = cva(['flex flex-col justify-between pt-[10px] min-h-[200px]']);
const button = (textClr: string) => 
    `${textClr} p-2 border rounded-md mb-2 font-medium hover:bg-gray-200 active:scale-95`;
const icon = cva(['mr-2 w-[16px] h-[16px]']);
const centeredContent = cva(['flex items-center pl-[50px]'])

interface OneButton {
    name: string;
    icon: React.FC<SVGProps<SVGSVGElement>>;
    link: string;
    textClr: string;
}


interface ReviewProps {
    reviewId: string;
}

const ReviewYes: React.FC<ReviewProps> = ({ reviewId }) => {
    const [buttons, setButtons] = useState<Array<OneButton>>([]);

    useEffect(() => {
        getInfo();
    }, []);
    
    const redirect = async (url: string, reviewId: string) => {
        await axios.post(`${process.env.REACT_APP_API_URL}review/update`, {
            reviewId: reviewId,
            field: 'redirectOnButton',
            value: url,
        })
    
        window.open(url, '_blank');
    }

    const fillButtons = (buttonData: { yandex: string; google: string; twoGis: string }) => {
        const allButtons: OneButton[] = [];
    
        if (buttonData.yandex) {
            allButtons.push({
                name: 'Яндекс карты',
                icon: YandexMapSvg,
                link: buttonData.yandex,
                textClr: 'text-black',
            });
        }
        
        if (buttonData.google) {
            allButtons.push({
                name: 'Google карты',
                icon: GoogleMapSvg,
                link: buttonData.google,
                textClr: 'text-sky-500',
            });
        }
        
        if (buttonData.twoGis) {
            allButtons.push({
                name: '2ГИС',
                icon: TwoGisSvg,
                link: buttonData.twoGis,
                textClr: 'text-green-600',
            });
        }
    
        setButtons(allButtons);
    };

    const getInfo = async () => {
        try {
            const response: any = await axios.get(`${process.env.REACT_APP_API_URL}review/info/${reviewId}`);
            fillButtons(response.data.buttons);
        } catch (error) {
            console.error('Error fetching info:', error);
        }
    };

    return (
        <>
            <h1 className={h1()}>Поделитесь мнением о Суперклинике</h1>
            <p className={p()}>Обычно люди не находят времени оставить отзывы, если им всё понравилось. <br/>А вы нашли. Мы это ценим.</p>
            <p className={p()}>В благодарность за Ваше время мы пополним баланс Вашего телефона на 200 рублей за отзыв на любом из сайтов:</p>
            <div className={buttonWrapper()}>
                {buttons.map((buttonData, index) => {
                    const Icon = buttonData.icon;

                    return (
                        <button 
                            key={index}
                            onClick={() => redirect(buttonData.link, reviewId)}
                            className={button(buttonData.textClr)}
                        >
                            <div className={centeredContent()}>
                                <Icon className={icon()} />
                                {buttonData.name}
                            </div>
                        </button>
                    )
                })}
            </div>
        </>
    )
}

export default ReviewYes